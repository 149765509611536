import React, { FC } from "react"

interface IProps {
  sidebar: any
  main: any
}

const FPBodyWrapper: FC<IProps> = ({ sidebar, main }) => {
  return (
    <div className="container">
      <div className="row">
        <aside id="left-col" className="col-12 col-lg-4">
          {sidebar}
        </aside>
        <main id="right-col" className="col-12 col-lg-8 pt-5">
          {main}
        </main>
      </div>
    </div>
  )
}

export default FPBodyWrapper
