/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { rgba } from "polished"
import { useTheme } from "emotion-theming"
import { IAppTheme } from "../theme"

const makeStyles = (theme: IAppTheme) => ({
  root: css`
    position: absolute;
    top: 0;
    background: ${rgba(theme.color.secondary, 0.3)};
    width: 100%;
    text-align: center;
    color: ${theme.color.white};
    z-index: 999999;
    padding: ${0.5 * theme.spacing.unit}px 0;
  `,
})

const TopBar = () => {
  const styles = makeStyles(useTheme())

  return (
    <div css={styles.root}>
      Quick prototype, no real functionalities added. Your messages reach me
      directly, though.
    </div>
  )
}

export default TopBar
