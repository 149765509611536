import React, { FC, Fragment } from "react"
import { graphql, useStaticQuery } from "gatsby"
import RatioImage from "../../components/RatioImage"
import { css } from "@emotion/core"
import { mq } from "../../utils/themeUtils"
import { IAppTheme } from "../../theme"
import { useTheme } from "emotion-theming"
import { rgba } from "polished"

const makeStyles = (theme: IAppTheme) => ({
  rootStyle: css`
    position: relative;
    margin: -35% auto 0.5rem;
    width: 80%;
    max-width: 400px;
    transition: width 100ms ease-in-out, margin-top 100ms ease-in-out;
    border: 0.25rem solid ${rgba(theme.color.secondary, 0.2)};
    border-radius: 50%;
    z-index: 2;

    ${mq.minWidth.lg} {
      &:hover {
        width: 82%;
        margin: -37% auto 0.5rem;
      }

      border-radius: 0.25rem;
    }
  `,
  imgWrapperStyle: css`
    background: transparent;
    border-radius: 50%;
    overflow: hidden;

    ${mq.minWidth.lg} {
      border-radius: 0.25rem;
    }
  `,

  avatarBadge: css`
    position: absolute;
    background: ${rgba("#0073c5", 0.8)};
    padding: 0.25rem 1rem;
    min-width: 150px;
    text-align: center;
    font-size: 0.8rem;
    font-style: italic;
    bottom: 0.125rem;
    transform: translateX(-50%);
    right: auto;
    left: 50%;

    ${mq.minWidth.lg} {
      transform: translateX(1rem);
      bottom: 0.5rem;
      right: 0;
      left: auto;
      text-align: unset;
      min-width: max-content;
    }

    a {
      color: #fff;
      text-decoration: none;
    }

    &::before {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      width: 0;
      height: 0;
      border-right: 0.75rem solid ${rgba("#0073c5", 0.4)};
      border-bottom: 0.25rem solid transparent;
      z-index: 1;

      ${mq.minWidth.lg} {
        display: none;
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: 100%;
      right: 0;
      width: 0;
      height: 0;
      border-left: 0.75rem solid ${rgba("#0073c5", 0.4)};
      border-bottom: 0.25rem solid transparent;
      z-index: 1;

      ${mq.minWidth.lg} {
        border-bottom: 0.5rem solid transparent;
      }
    }
  `,

  underAvatar: css`
    text-align: center;
    margin-bottom: 3rem;

    h1 {
      font-size: 2rem;
      margin-bottom: 0;
    }

    span {
      font-style: italic;
    }
  `,
})

const MyAvatar: FC = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "avatar - khoa - 1x1.jpg" }) {
        ...AvatarImage_withWebp
      }
    }
  `)

  const avatar = data.file.childImageSharp.fluid

  const styles = makeStyles(useTheme())

  return (
    <Fragment>
      <RatioImage
        width={"80%"}
        ratio={{ x: 1, y: 1 }}
        fluid={avatar}
        rootStyle={styles.rootStyle}
        imgWrapperStyle={styles.imgWrapperStyle}
        append={
          <div css={styles.avatarBadge}>
            <a
              href="https://www.facebook.com/mgmTechnologyPartnersVietnam/"
              target="_blank"
            >
              joined mgm VN
            </a>
          </div>
        }
      />
      <div css={styles.underAvatar}>
        <h1>Khoa Liber</h1>
        <span>A forward-thinking developer</span>
      </div>
    </Fragment>
  )
}

export default MyAvatar
