import React, { FC, Fragment } from "react"
import FPSidebarWidget from "./SidebarWidget"
import MyAvatar from "./MyAvatar"
import { css } from "@emotion/core"
import TechGridWidget from "./TechGridWidget"
import SkillSetWidget from "./SkillSetWidget"

const FPSidebar: FC = () => {
  return (
    <Fragment>
      <MyAvatar />

      <FPSidebarWidget header="Bio">
        <p
          css={css`
            text-align: justify;
          `}
        >
          An enthusiastic developer who loves solving problems, working with
          ideas and providing solutions by coding. I have been working as a
          full-time developer since 2017, mainly in front-end for the last 2
          years. I am a self-taught developer, but I learn things deeply, know
          the ins and outs, always care about security and performance. I am
          experienced with WordPress, React/Redux eco-system and ready to jump
          right into any given projects.
        </p>
      </FPSidebarWidget>

      <TechGridWidget />
      <SkillSetWidget />
    </Fragment>
  )
}

export default FPSidebar
