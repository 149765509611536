/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { FC } from "react"
import { useTheme } from "emotion-theming"
import { IAppTheme } from "../../theme"
import { FluidObject } from "gatsby-image/index"
import { mq } from "../../utils/themeUtils"
import RatioImage from "../../components/RatioImage"
import { lighten } from "polished"

export interface IProps {
  thumbnail?: FluidObject
  title?: string
  content?: any
  isFeatured?: boolean
}

const makeStyles = (theme: IAppTheme) => ({
  featuredPost: css`
    background: ${theme.color.gray};
    border-radius: 0.5rem;
    overflow: hidden;
    margin-bottom: 2rem;

    section {
      padding: 1rem;
    }
  `,
  regularPost: css`
    display: flex;
    background: ${theme.color.gray};
    border-radius: 0.5rem;
    overflow: hidden;
    margin-bottom: 2rem;
    min-height: 120px;

    ${mq.minWidth.xl} {
      min-height: 200px;
    }

    section {
      flex: 2 1.5 500px;
      padding: 1rem;
      margin-bottom: 0;
      align-self: center;

      ${mq.minWidth.md} {
        flex: 2 2 500px;
        align-self: flex-start;
      }

      p {
        display: none;

        ${mq.minWidth.md} {
          display: block;
        }

        &:first-of-type {
          margin-bottom: 0 !important;
        }
      }
    }

    .post-heading {
      font-size: 1.2rem;

      ${mq.minWidth.sm} {
        font-size: 1.3rem;
      }
    }
  `,
  postHeading: css`
    font-size: 1.3rem;

    ${mq.minWidth.md} {
      font-size: 1.4rem;
    }

    ${mq.minWidth.lg} {
      font-size: 1.5rem;
    }
  `,
  withHover: css`
    &:hover {
      box-shadow: 3px 5px 0 0 ${lighten(0.4, theme.color.primary)};
      transform: translateY(-1px);
      transition: all 100ms ease-in-out;
    }
  `,
})

const FPSinglePost: FC<IProps> = ({
  title,
  thumbnail,
  content,
  isFeatured,
}) => {
  const styles = makeStyles(useTheme())

  return isFeatured ? (
    <article css={[styles.featuredPost, styles.withHover]}>
      {thumbnail && (
        <RatioImage fluid={thumbnail} width={"100%"} ratio={{ x: 21, y: 9 }} />
      )}

      <section>
        <h4 className="post-heading" css={styles.postHeading}>
          {title}
        </h4>
        <p>{content}</p>
      </section>
    </article>
  ) : (
    <article css={[styles.regularPost, styles.withHover]}>
      {thumbnail && (
        <RatioImage
          fluid={thumbnail}
          width={"200px"}
          ratio={[
            { x: 1, y: 1.2 },
            { mq: mq.minWidth.md, x: 1, y: 1 },
            { mq: mq.minWidth.lg, x: 3, y: 4 },
            { mq: mq.minWidth.xl, x: 1, y: 1 },
          ]}
        />
      )}

      <section>
        <h4 className="post-heading" css={styles.postHeading}>
          {title}
        </h4>
        <p>{content}</p>
      </section>
    </article>
  )
}

export default FPSinglePost
