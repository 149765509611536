import React, { FC, Fragment } from "react"
import FPSinglePost, { IProps as ISinglePostProps } from "./SinglePost"
import { graphql, useStaticQuery } from "gatsby"

const FPPosts: FC = () => {
  const postImages = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "posts/fireworks-1496130_1920.jpg" }) {
        ...PostFeaturedThumbnail_withWebp
      }
      image2: file(relativePath: { eq: "posts/cat-4864605_1280.jpg" }) {
        ...PostThumbnail_withWebp
      }
      image3: file(
        relativePath: { eq: "posts/dwarf-rabbit-4887896_1280.jpg" }
      ) {
        ...PostThumbnail_withWebp
      }
      image4: file(relativePath: { eq: "posts/butterfly-4881977_1280.jpg" }) {
        ...PostThumbnail_withWebp
      }
      image5: file(
        relativePath: { eq: "posts/lilac-breasted-roller-4869674_1280.jpg" }
      ) {
        ...PostThumbnail_withWebp
      }
      image6: file(relativePath: { eq: "posts/rock-dove-4884627_1280.jpg" }) {
        ...PostThumbnail_withWebp
      }
    }
  `)

  const loremText = `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam aut et facere hic ipsum magnam omnis ullam voluptatem? Consequatur ducimus enim maxime quia. Dolorum ea fugit, impedit itaque iusto non?`
  const posts: (ISinglePostProps & { id: string })[] = [
    {
      id: "post-1",
      title: "Either you run the day or the day runs you. - Jim Rohn",
      thumbnail: postImages.image1.childImageSharp.fluid,
      content: loremText,
      isFeatured: true,
    },
    {
      id: "post-2",
      title:
        "Just one small positive thought in the morning can change your whole day. - Dalai Lama",
      thumbnail: postImages.image2.childImageSharp.fluid,
      content: loremText,
    },
    {
      id: "post-3",
      title:
        "Whether you think you can, or you think you can’t – you’re right. - Henry Ford",
      thumbnail: postImages.image3.childImageSharp.fluid,
      content: loremText,
    },
    {
      id: "post-4",
      title:
        "People rarely succeed unless they have fun in what they are doing. - Dale Carnegie",
      thumbnail: postImages.image4.childImageSharp.fluid,
      content: loremText,
    },
    {
      id: "post-5",
      title: "Don't wait. The time will never be just right. - Napoleon Hill",
      thumbnail: postImages.image5.childImageSharp.fluid,
      content: loremText,
    },
  ]

  return (
    <Fragment>
      {posts.map(({ id, title, thumbnail, content, isFeatured }) => (
        <FPSinglePost
          key={id}
          title={title}
          thumbnail={thumbnail}
          content={content}
          isFeatured={isFeatured}
        />
      ))}
    </Fragment>
  )
}

export default FPPosts
