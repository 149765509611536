import { css } from "@emotion/core"
import { graphql, useStaticQuery } from "gatsby"
import RatioImage from "../../components/RatioImage"
import React from "react"
import FPSidebarWidget from "./SidebarWidget"

const makeStyles = () => ({
  gridWrapper: css`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  `,
  imgRootStyle: css`
    flex: 1 1 calc(1 / 3 * 100%);
    overflow: hidden;
    border: 3px solid transparent;
    border-radius: 0.5rem;

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  `,
  imgStyle: {
    transition: "transform 100ms ease-in-out",
    cursor: "pointer",
  },
})

const TechGridWidget = () => {
  const data = useStaticQuery(graphql`
    query {
      JavaScript: file(
        relativePath: {
          eq: "frontPage/techGrid/javascript-icon_compressed.jpg"
        }
      ) {
        ...TechGridIcon_withWebp
      }
      React: file(relativePath: { eq: "frontPage/techGrid/react.png" }) {
        ...TechGridIcon_withWebp
      }
      Gatsby: file(relativePath: { eq: "frontPage/techGrid/gatsby.png" }) {
        ...TechGridIcon_withWebp
      }
      TypeScript: file(
        relativePath: { eq: "frontPage/techGrid/typescript icon.png" }
      ) {
        ...TechGridIcon_withWebp
      }
      HTML: file(relativePath: { eq: "frontPage/techGrid/html icon.jpg" }) {
        ...TechGridIcon_withWebp
      }
      CSS: file(relativePath: { eq: "frontPage/techGrid/css icon.png" }) {
        ...TechGridIcon_withWebp
      }
      NodeJs: file(relativePath: { eq: "frontPage/techGrid/nodejs icon.jpg" }) {
        ...TechGridIcon_withWebp
      }
      PHP: file(relativePath: { eq: "frontPage/techGrid/php icon.jpg" }) {
        ...TechGridIcon_withWebp
      }
      MySQL: file(
        relativePath: { eq: "frontPage/techGrid/mysql icon_compressed.jpg" }
      ) {
        ...TechGridIcon_withWebp
      }
    }
  `)

  const styles = makeStyles()

  return (
    <FPSidebarWidget header="Tech Stack">
      <div css={styles.gridWrapper}>
        {Object.keys(data)?.map(imgKey => {
          const img = data[imgKey]

          return (
            <RatioImage
              key={img.childImageSharp.id}
              width={""}
              ratio={{ x: 1, y: 1 }}
              fluid={img.childImageSharp.fluid}
              rootStyle={styles.imgRootStyle}
              imgStyle={styles.imgStyle}
              otherGatsbyImageProps={{
                alt: imgKey,
                title: imgKey,
              }}
            />
          )
        })}
      </div>
    </FPSidebarWidget>
  )
}

export default TechGridWidget
