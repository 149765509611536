/** @jsx jsx */
import { jsx } from "@emotion/core"
import React, { Fragment } from "react"
import SEO from "../../components/SEO"
import "bootstrap/dist/css/bootstrap-reboot.min.css"
import "bootstrap/dist/css/bootstrap-grid.min.css"
import TopBar from "../../components/TopBar"
import SiteFooter from "../../components/SideFooter"
import FPHeroHeader from "./HeroHeader"
import FPBodyWrapper from "./BodyWrapper"
import FPSidebar from "./Sidebar"
import FPPosts from "./Posts"
import FPWhatsOnMyMind from "./WhatsOnMyMind"

const FrontPageWrapper = () => {
  return (
    <Fragment>
      <SEO title="Home" />
      <TopBar />
      <FPHeroHeader />

      <FPBodyWrapper
        sidebar={<FPSidebar />}
        main={
          <Fragment>
            <FPWhatsOnMyMind />
            <FPPosts />
          </Fragment>
        }
      />

      <SiteFooter />
    </Fragment>
  )
}

export default FrontPageWrapper
