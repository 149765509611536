/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { rgba } from "polished"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { useTheme } from "emotion-theming"
import { IAppTheme } from "../theme"

const makeStyles = () => ({
  root: css`
    position: relative;
    height: 180px;
    margin-top: -2rem;

    .container {
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      padding-bottom: 3rem;
    }
  `,
  background: css`
    height: 100%;
    width: 100%;
    position: absolute;
    background-size: cover;
    z-index: -1;
  `,
  copyright: css`
    text-align: center;
    margin-top: 2rem;
  `,
})

const SiteFooter = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "frontPage/vietnam-2672415_1920.jpg" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const backgroundImage = data.file.childImageSharp.fluid

  const theme = useTheme<IAppTheme>()
  const styles = makeStyles()

  return (
    <footer css={styles.root}>
      <BackgroundImage
        css={styles.background}
        fluid={[
          `linear-gradient(to top, 
          ${rgba(theme.color.white, 0.5)} 0%, 
          ${rgba(theme.color.white, 0.8)} 10%, 
          ${rgba(theme.color.white, 1)} 80%, 
          ${rgba(theme.color.white, 1)} 100%)
      `,
          backgroundImage,
        ]}
      >
        <div className="container">
          <span css={styles.copyright}>
            Copyright © {new Date().getFullYear()} Khoa Liber. All rights
            reserved.
          </span>
        </div>
      </BackgroundImage>
    </footer>
  )
}

export default SiteFooter
